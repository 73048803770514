import { HelmetProvider } from "react-helmet-async";
import { useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./App.scss";
import Sidebar from "./components/Sidebar/Sidebar";
import Footer from "./components/Footer/Footer";

import Whatsapp from "/assets/imgs/wotsup.webp";
import BackgroundMobile from "/assets/imgs/newBckgrd-640.webp";
import BackgroundSmall from "/assets/imgs/newBckgrd-1350.webp";
import BackgroundLarge from "/assets/imgs/newBckgrd-3676.webp";

// Lazy-load pages
import Home from "./Pages/Home";
const Technologies = lazy(() => import("./Pages/Technologies"));
const AboutUs = lazy(() => import("./Pages/AboutUs"));
const Services = lazy(() => import("./Pages/Services"));
const ClientsSamples = lazy(() => import("./Pages/ClientsExample"));
const Philosophy = lazy(() => import("./Pages/Philosophy"));
const ContactUs = lazy(() => import("./Pages/ContactUs"));
const Pricing = lazy(() => import("./Pages/Pricing"));
const Whyus = lazy(() => import("./Pages/Whyus"));
const Analyze = lazy(() => import("./Pages/Analyze"));
const NotFound = lazy(() => import("./Pages/notfound"));

import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n } = useTranslation();
  const [showWidget, setShowWidget] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowWidget(true);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <HelmetProvider>
      <div className={i18n.dir() === "rtl" ? "rtl" : ""}>
        {/* <div id="particles-js" className="particles-container"></div> */}
        <div className="App">
          <img
            alt="Background"
            className="background"
            srcSet={`
              ${BackgroundMobile} 600w,
              ${BackgroundSmall} 1350w,
              ${BackgroundLarge} 3676w
            `}
            sizes="(max-width: 600px) 50vw, 
                  (max-width: 1350px) 100vw, 
                  100vw"
          />
          <Router>
            <Sidebar />
            <div className="contentWrapper">
              <AnimatePresence mode="wait">
                <Suspense>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Technologies" element={<Technologies />} />
                    <Route path="/ContactUs" element={<ContactUs />} />
                    <Route path="/AboutUs" element={<AboutUs />} />
                    <Route
                      path="/ClientsSamples"
                      element={<ClientsSamples />}
                    />
                    <Route path="/Services" element={<Services />} />
                    <Route path="/Analyze" element={<Analyze />} />
                    <Route path="/Philosophy" element={<Philosophy />} />
                    <Route path="/Pricing" element={<Pricing />} />
                    <Route path="/Whyus" element={<Whyus />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </AnimatePresence>
            </div>
            <Footer />
          </Router>
          {showWidget && (
            <div
              id="whatsapp-floating-widget"
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              <a
                href="https://wa.me/message/NSGEG4K5EQWIM1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Whatsapp}
                  alt={t("whatsappAlt")}
                  style={i18n.language === "ar" ? { float: "left" } : {}}
                />
                <div className="widget-text" id="whatsapp-widget">
                  {t("chatWithExpert")}
                  <br />
                  {t("noCharges")}
                </div>
              </a>
            </div>
          )}
        </div>
      </div>
    </HelmetProvider>
  );
}

export default App;
